@import '../../variables.scss';

.recruitment-padder {
  height: 3.6em;
  display: none;

  &.visible {
    display: block;
  }
}

.navbar{

    .dropdown-toggle::after {
      border: none;
    }

    .nav-item.dropdown:hover .dropdown-menu {
      display: block;
    }

    .dropdown-menu {
      padding: 1.5em;
    }

    @media (max-width:992px){
      .navbar-nav {
        padding-top: 1rem;
      }

      .dropdown-menu {
        display: block;
        border: none;
        text-align: left;
        padding: 0;
      }

      .nav-link {
        text-align: left;
        font-weight: bold !important;
      }
    }

    .dropdown-menu .tree {
      list-style-type: none;
      padding-left: 20px;

      li {
      }

      ul.nested {
        margin-top: 1em;
        margin-bottom: 1em;
        display: none;

        &.active {
          display: block;
        }
      }

      /* Style the caret/arrow */
      .caret {
        cursor: pointer;
        user-select: none; /* Prevent text selection */
        position: relative;
        color: black;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.05em;
      }

      /* Create the caret/arrow with a unicode, and style it */
      .caret::before {
        content: "+";
        position: absolute;
        left: -17px;
        color: $mainColor;
        display: inline-block;
        margin-right: 6px;
      }

      .caret.active::before {
        content: "-";
      }

      .link {
        position: relative;
      }

      .link::before {
        content: ">";
        position: absolute;
        left: -17px;
        color: $mainColor;
        display: inline-block;
        margin-right: 6px;
      }

      ul {
        list-style-type: none;
        padding-left: 15px;
      }

      a {
        color: black;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.05em;
      }
    }

    background-color: #fff;
    border-radius:0;
    border: none; // resetting
    border-bottom: 1px solid #eee;
    margin-bottom:0;

    &.shrink{
      background-color:red;
    }

    
    .navbar-brand{
        padding:10px 15px 10px 15px;
        height:auto;

        &:hover, &:focus, &:active{
          &:after{
            width:0;
          }
        }
        @media (min-width:992px){
            position: absolute;
        }

        .logo{
            width:240px;

            
        }
        @media (max-width:991px){

            padding:0px 0;
            .logo{
                width:90px;
            }
        }
    }

    @media (min-width:992px){
        .navbar-collapse{
            height: auto !important;
        }
    }

    .mobile-links-container {
      margin: 0 auto;
      margin-top: 20px;
    }

    .intralink{

      display:inline-block;
      color:#fff;
      background-color: $mainColor;
      padding: 10px 20px;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.05em;
      -webkit-transition: background-color 250ms ease-in-out;
      transition: background-color 250ms ease-in-out;
      text-transform: uppercase;

      &:hover, &:focus, &:active{
        text-decoration: none;
        outline: none;
        background-color: #333333;
        color:#fff;

        &:after{
          width:0;
        }
      }

      &.mobile{
        display: none;
      }

      &.intralink2 {
        background-color: white;
        color: $mainColor;
        border: 2px solid $mainColor;
        transition: background-color 250ms ease-in-out, color 250ms ease-in-out;
        margin-right: 20px;
        margin-left: -70px;

        &:hover, &:focus, &:active {
          background-color: $mainColor;
          color: #fff;
        }
      }

      @media (max-width:991px){
        &.mobile{
          display:inline-block;
          width: 120px;
          
          &:first-child {
          }
        }
        &.desktop{
          display:none;
        }
      }
    }
    

    .navbar-nav{
        padding-left: 70px;

        a.nav-link{
            color: $text-color;
            font-size:14px;
            text-transform: uppercase;
            font-weight: 500;
            position: relative;
            letter-spacing: 0.05em;
            padding:1rem;
            @media (max-width:991px){
                padding:0.5rem;
            }

            &:after{
                content:'';
                position: absolute;
                display:block;
                height:2px;
                width:0;
                background-color: $mainColor;
                bottom:10px;
                left:50%;
                -webkit-transform: translateX(-50%);
                        transform: translateX(-50%);
                -webkit-transition: width 250ms ease-in-out;
                transition: width 250ms ease-in-out;

            }
            &.active:after{
                width:30%;
            }
            &:hover:after{
                @media (min-width:992px){
                    width:30%;
                }
                
            }
        }       

        @media (max-width:991px){
          text-align:center;
          a.nav-link{
            &.active:after{
              width:0;
            }
            &:hover:after{
              width:0;
            }
            -webkit-transition: all 250ms ease-in-out;
            transition: all 250ms ease-in-out;
            &.active, &:hover, &:focus{
              color: $mainColor;
            }
          }
        }
    }

    $speed: 200ms;
    $easing: cubic-bezier(0.7, 0, 0, 0.7);
    
    .navbar-toggle {
      overflow: hidden;
      background-color: transparent;
      border:none;
      margin-top:0px;
      margin-bottom:0;
      outline: none;
      @media (min-width: 992px){
        display:none;
      }
    
      .icon-bar {
        -webkit-transition: opacity, -webkit-transform;
        transition: opacity, -webkit-transform;
        transition: opacity, transform;
        transition: opacity, transform, -webkit-transform;
        -webkit-transition-duration: $speed;
                transition-duration: $speed;
        -webkit-transition-timing-function: $easing;
                transition-timing-function: $easing;
        background-color:#333;
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;

        &+.icon-bar{
            margin-top: 4px;
        }
      }
    
      &:not(.collapsed) {
        .icon-bar {
          &:nth-child(1) {
            -webkit-transform: translateY(6px) rotate(45deg);
                    transform: translateY(6px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
            -webkit-transform: translateX(-100%);
                    transform: translateX(-100%);
          }
          &:nth-child(3) {
            -webkit-transform: translateY(-6px) rotate(-45deg);
                    transform: translateY(-6px) rotate(-45deg);
          }
        }
      }
    }
}

#recruitment-banner {
  position: relative;
  display: none;

  text-align: center;
  min-height: 3em;
  background-color: black;
  color: white;
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0;

  &.visible {
    display: block;
  }

  span {
    line-height: 2.5em;
  }

  a.btn-blue {
    min-width: 12em;
    margin-left: 3em;
  }

  a.close-button {
    color: white;
    position: absolute;
    top: 0.5em;
    right: 1em;
    font-size: 30px;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;

    a.close-button {
      top: 0.25rem;
      right: 0.25rem;
    }
  }
}